import axios from 'axios'
const AUTH_LOCAL_STORAGE_KEY = 'wcl-auth'
const GHL_LOCAL_STORAGE_KEY = 'wcl-crm'
const AGENCY_SESSION_STORAGE_KEY = 'dir-age-id'

const getAuth = () => {
  if (!localStorage) {
    return
  }

  const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth = JSON.parse(lsValue)
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getGHLSessionKey = () => {
  if (!localStorage) {
    return
  }

  const lsValue = localStorage.getItem(GHL_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth = JSON.parse(lsValue)
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('GHL KEY LOCAL STORAGE PARSE ERROR', error)
  }
}

const setGHLSessionKey = (key) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(key)
    localStorage.setItem(GHL_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('GHL KEY LOCAL STORAGE SAVE ERROR', error)
  }
}

const isGHLPlatform = () => {
  return window.self != window.top
}

const getAgencyKey = () => {
  if (!sessionStorage) {
    return
  }

  const ssValue = sessionStorage.getItem(AGENCY_SESSION_STORAGE_KEY)
  if (!ssValue) {
    return
  }

  try {
    const auth = JSON.parse(ssValue)
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AGENCY ID SESSION STORAGE PARSE ERROR', error)
  }
}

const setAgencyKey = (locationKey) => {
  if (!sessionStorage) {
    return
  }

  try {
    const ssValue = JSON.stringify(locationKey)
    sessionStorage.setItem(AGENCY_SESSION_STORAGE_KEY, ssValue)
  } catch (error) {
    console.error('AGENCY ID SESSION STORAGE SAVE ERROR', error)
  }
}

const removeAgencyKey = () => {
  if (!localStorage) {
    return
  }

  try {
    sessionStorage.removeItem(AGENCY_SESSION_STORAGE_KEY)
  } catch (error) {
    console.error('AGENCY KEY SESSION REMOVE ERROR', error)
  }
}

function setupAxios() {
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')

  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config) => {
      const auth = getAuth()
      const agencyKey = getAgencyKey()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      if (agencyKey) {
        config.headers['X-API-KEY'] = `Bearer ${agencyKey}`
      }

      return config
    },
    (err) => Promise.reject(err)
  )
}

export {
  getAuth,
  setAuth,
  removeAuth,
  AUTH_LOCAL_STORAGE_KEY,
  getGHLSessionKey,
  setGHLSessionKey,
  isGHLPlatform,
  GHL_LOCAL_STORAGE_KEY,
  getAgencyKey,
  setAgencyKey,
  removeAgencyKey,
  AGENCY_SESSION_STORAGE_KEY,
  setupAxios,
}
