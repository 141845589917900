import { AuthInit } from 'context/auth'
import { useRoutes } from 'react-router-dom'
import Routes from 'routing/routes'

function App() {
  const routes = useRoutes(Routes())

  return (
    <div className='h-screen overflow-y-auto relative'>
      <AuthInit>{routes}</AuthInit>
    </div>
  )
}

export default App
