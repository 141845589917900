import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import AdminLayout from 'screens/admin/AdminLayout'
import { Navigate } from 'react-router-dom'

// DIRECTORIES
const Business = ComponentLoader(
  lazy(() => import('screens/admin/Directories/directories'))
)
const UpdateBusiness = ComponentLoader(
  lazy(() => import('screens/admin/Directories/update'))
)

// CONSUMERS
const Consumers = ComponentLoader(
  lazy(() => import('screens/admin/Consumers/directories'))
)

// PAGES
const Pages = ComponentLoader(lazy(() => import('screens/admin/Pages/pages')))

const Customize = ComponentLoader(
  lazy(() => import('screens/admin/Pages/customize'))
)
const PreviewPage = ComponentLoader(
  lazy(() => import('screens/admin/Pages/preview'))
)

// TEAM
const TeamsModule = ComponentLoader(lazy(() => import('screens/admin/teams')))
const Teams = ComponentLoader(lazy(() => import('screens/admin/teams/teams')))

// SETTINGS
const Profile = ComponentLoader(
  lazy(() => import('screens/admin/Profile/profile'))
)

const Integration = ComponentLoader(
  lazy(() => import('screens/admin/Integration/integration'))
)
const IntegrateStripe = ComponentLoader(
  lazy(() => import('screens/public/stripe/stripe'))
)

const PageModule = ComponentLoader(
  lazy(() => import('screens/admin/Pages/index'))
)

const AdminRoutes = () => {
  return [
    {
      path: '',
      element: <AdminLayout />,
      children: [
        {
          path: 'business',
          children: [
            {
              path: '',
              index: true,
              element: <Business />,
            },
            {
              path: 'update',
              index: true,
              element: <UpdateBusiness />,
            },
          ],
        },
        {
          path: 'consumer',
          children: [
            {
              path: '',
              index: true,
              element: <Consumers />,
            },
            {
              path: 'update',
              index: true,
              element: <UpdateBusiness />,
            },
          ],
        },
        {
          path: 'Teams',
          element: <TeamsModule />,
          children: [
            {
              path: '',
              index: true,
              element: <Teams />,
            },
          ],
        },
        {
          path: 'pages',
          element: <PageModule />,
          children: [
            {
              path: '',
              index: true,
              element: <Pages />,
            },
            {
              path: 'customize',
              index: true,
              element: <Customize />,
            },
            {
              path: 'preview-page',
              element: <PreviewPage />,
            },
          ],
        },
        {
          path: 'integration',
          element: <IntegrateStripe />,
        },
        {
          path: 'profile',
          element: <Profile />,
        },
        {
          path: 'settings',
          element: <Integration />,
        },
        {
          path: '',
          index: true,
          element: <Navigate replace to={'./business'} />,
        },
        {
          path: '*',
          index: true,
          element: <Navigate replace to={'./business'} />,
        },
      ],
    },
    {
      path: 'integrations',
      children: [
        {
          path: 'stripe',
          element: <IntegrateStripe />,
        },
      ],
    },
    {
      path: '',
      element: <Navigate replace to={'./'} />,
    },
    {
      path: '*',
      element: <Navigate replace to={'./'} />,
    },
  ]
}

export default AdminRoutes
