import React, { useState, createContext, useContext, useEffect } from 'react'
import Api from 'utils/api'
import * as authHelper from 'utils/auth-helper'

const initAgencyContextPropsState = {
  agency: undefined,
  setAgency: () => {},
  getAgency: async (selected_location) => {},
}

const AgencyContext = createContext(initAgencyContextPropsState)

const useAgency = () => {
  return useContext(AgencyContext)
}

const AgencyProvider = ({ children }) => {
  const [agency, setAgency] = useState({})

  const getAgency = async () => {
    const { response } = await Api('/agency', 'get')
    if (response) setAgency(response.data)
    else setAgency()
  }

  const saveAgencyKey = (agencyKey) => {
    if (agencyKey) authHelper.setAgencyKey(agencyKey)
  }

  useEffect(() => {
    console.log('AGENCY FOUND', agency)
    if (agency?._id) {
      saveAgencyKey(agency.api_key)
    }
  }, [agency])

  return (
    <AgencyContext.Provider
      value={{
        agency,
        setAgency,
        getAgency,
      }}
    >
      {children}
    </AgencyContext.Provider>
  )
}

export { AgencyProvider, useAgency }
