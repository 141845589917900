import TopHeader from 'component/TopHeader'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AdminLayout() {
  return (
    <>
      <div className='flex flex-col'>
        <TopHeader />
        <main className='p-4 h-[calc(100vh-74px)]'>
          <Outlet />
        </main>
      </div>
    </>
  )
}

export default AdminLayout
