const TYPES = {
  AGENCY: "agency_user",
  LOCATION: "location_user",
  SUBSCRIBER: "subscriber",
  SUPER_ADMIN: "super_admin",
};

const ROLES = {
  AGENCY: "agency",
  BUSINESS_ADMIN: "business_admin",
};

export { TYPES, ROLES };
