import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react'
import Api from 'utils/api'
import * as authHelper from 'utils/auth-helper'
import { useAgency } from './agency'
import Loader from 'component/loader'
import { useAuth } from './auth'

const initLocationContextPropsState = {
  locationKey: undefined,
  saveLocationKey: () => {},
  location: undefined,
  setLocation: () => {},
  locations: [],
  setLocations: () => {},
  getLocations: async () => {},
  saasPlan: undefined,
  isAccessable: () => Boolean,
}

const LocationContext = createContext(initLocationContextPropsState)

const useLocationInfo = () => {
  return useContext(LocationContext)
}

const LocationProvider = ({ children }) => {
  const [locationKey, setLocationKey] = useState()
  const [location, setLocation] = useState()
  const [locations, setLocations] = useState([])
  const [saasPlan, setSaasPlan] = useState()

  const getLocations = async () => {
    const { response } = await Api('/location/user_locaitons', 'get')
    if (response) {
      setLocations(response.data)
      return response.data || []
    } else setLocations([])
  }

  const saveLocationKey = (locationKey) => {
    setLocationKey(locationKey)
    // if (locationKey) authHelper.setLocationKey(locationKey)
  }

  const getSaasPlan = async () => {
    const { response } = await Api(
      '/saas-config/query?_id=' + location.saas_plan,
      'get'
    )
    if (response) setSaasPlan(response.data[0] || {})
  }

  useEffect(() => {
    if (location?.saas_plan) getSaasPlan()
  }, [location])

  const isAccessable = (key, depth = 0) => {
    if (!saasPlan) return true
    return saasPlan?.features?.find((uid) => {
      const uids = uid.split('.')
      return uids[depth] == key
    })
  }

  return (
    <LocationContext.Provider
      value={{
        locationKey,
        saveLocationKey,
        location,
        setLocation,
        locations,
        setLocations,
        getLocations,
        saasPlan,
        isAccessable,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}

const LocationInit = ({ children }) => {
  const { locationKey, locations, saveLocationKey, setLocation } =
    useLocationInfo()
  const { getAgency } = useAgency()
  const { logout } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const resetLocationKey = () => {
    const key = locations[0]?.api_key
    saveLocationKey(key)
  }

  useEffect(() => {
    const requestLocation = async () => {
      try {
        console.log(didRequest.current, locationKey)
        if (didRequest.current != locationKey) {
          setShowSplashScreen(true)
          const { response } = await Api('/auth/verify_api_key', 'get')
          if (response) {
            setLocation(response.data)
            await getAgency(response.data?._id)
          }
          // else resetLocationKey()
        }
      } catch (error) {
        console.error(error)
        // resetLocationKey()
        // if (!didRequest.current) {
        // logout()
        // }
      } finally {
        setShowSplashScreen(false)
      }

      didRequest.current = locationKey
    }

    if (locationKey) {
      requestLocation()
    } else {
      // logout()
      setShowSplashScreen(false)
    }
  }, [locationKey])

  return showSplashScreen ? (
    <div className='w-full h-[100vh] flex flex-col items-center justify-center'>
      <Loader />
    </div>
  ) : (
    <>{children}</>
  )
}

export { LocationProvider, useLocationInfo, LocationInit }
